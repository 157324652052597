<script setup>
const props = defineProps({
  markOnly: {
    type: Boolean,
    default: false,
  },
})

const viewBox = computed(() =>
  props.markOnly ? "0 0 300 313" : "0 0 1576 313"
)
</script>

<template>
  <div
    class="w-full"
    :class="{ 'max-w-[125px]': !markOnly, 'max-w-[24px]': markOnly }"
  >
    <svg
      class="w-full"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :viewBox="viewBox"
    >
      <path
        v-if="!markOnly"
        class="cls-1 text-slate-800"
        fill="currentColor"
        d="M384.13,262.73V156.64H484.88V123.18H384.13V47.35H493.42V13.53H345.68v249.2Zm219.16,4.63c57,0,98.26-42,98.26-97.55s-40.58-97.9-97.9-97.9-98.61,42-98.61,97.9S546,267.36,603.29,267.36Zm0-33.82c-34.88,0-60.16-26.7-60.16-63.73,0-36.67,24.92-63.72,60.16-63.72s60.17,26.7,60.17,63.72C663.46,206.48,638.54,233.54,603.29,233.54Zm165.77,29.19V170.88c0-34.53,18.15-58.38,49.84-58.38h17.44V76.18H820c-26.35,0-44.15,17.8-49.84,32.76h-2.5V76.54h-36V262.73Zm132.65,0V153.08c0-27.41,18.52-46.28,42-46.28S984,123.18,984,149.16V262.73h37.38V154.15c0-29.91,18.87-47.35,42.36-47.35,24.57,0,39.16,16.38,39.16,46.64V262.73h37.38V140.62c0-40.23-27-68.71-68-68.71-25.28,0-44.86,9.26-57.32,31.33h-2.49c-10.68-18.87-30.26-31.33-57-31.33s-44.14,13.53-52.33,29.19h-2.49V76.54H864.33V262.73Zm318.13,0V183.34l31.33-36.67,71.2,116.06h45.93l-91.5-146.32,87.94-102.88h-47.35l-95.41,115h-2.14v-115H1181.4v249.2ZM1408.39,47.35c14.6,0,24.92-9.61,24.92-23.85C1433.31,10,1423,0,1408.39,0c-14.24,0-24.56,10-24.56,23.5C1383.83,37.74,1394.15,47.35,1408.39,47.35Zm18.51,215.38V76.54h-37.38V262.73Zm146.54,0V231h-25.27c-19.58,0-27.06-10.68-27.06-28.48v-95.4h54.11V76.54h-54.11V23.85h-35.24V64.79c0,7.48-4.63,12.11-11,12.11h-24.56v30.26h33.46v96.47c0,38.81,22.07,59.1,58.74,59.1Z"
      />
      <path
        class="cls-2 text-pink-500"
        fill="currentColor"
        d="M300,262.73v50H150v-50Zm-250-50v50H0v-50Zm50-50v50H50v-50Zm50-50v50H100v-50Zm-50-50v50H50v-50Zm-50-50v50H0v-50Z"
      />
    </svg>
  </div>
</template>

<style scoped>
.cls-1 {
  fill: rgb(29, 27, 27);
}

.cls-2 {
  fill-rule: evenodd;
}
</style>
